import { Buffer } from "buffer";

import {
    CDN_ACCESS_TYPES,
    CDN_GRANTED_ENTITY_TYPES,
    GENERATE_ZIP_URL,
} from "~app/constants";
import { makeRequest } from "~services/requestHelpers";
import { logError } from "~services/sentry";

const uploadsCache = {};

export async function fetchAttachmentSafely({ id, url, token }) {
    try {
        const attachmentUrl = await fetchAttachment({ id, url, token });

        return attachmentUrl;
    } catch (e) {
        return null;
    }
}

export async function fetchAttachmentsZipSafely({
    attachmentIdList,
    token,
    onError,
}) {
    try {
        const zipUrl = await fetchAttachmentsZip({
            attachmentIdList,
            token,
        });

        return zipUrl;
    } catch (error) {
        onError(error);

        // error gets logged to sentry in the request helpers so we don't need to handle it here
        return null;
    }
}

async function fetchAttachmentsZip({ attachmentIdList, token }) {
    // get signed zip url after cdn uploads it to cdn
    const zipResponse = await makeRequest({
        url: GENERATE_ZIP_URL,
        method: "POST",
        body: JSON.stringify({ attachment_ids: attachmentIdList }),
        token,
    });

    return zipResponse.url;
}

async function fetchAttachment({ id, url, token }) {
    const blob = await makeRequest({
        url: url || `${process.env.CDN_URL}/${id}`,
        method: "GET",
        token,
        shouldParseAsBlob: true,
    });

    return URL.createObjectURL(blob);
}

export async function uploadAttachment({ attachment, token, permissions }) {
    if (!attachment) return "";
    try {
        const uploadedAttachment = await uploadAttachmentCore({
            attachment,
            token,
            permissions,
        });

        return uploadedAttachment;
    } catch (error) {
        logError({ error });

        throw error;
    }
}

async function uploadAttachmentCore({ attachment, token, permissions }) {
    if (!attachment) return null;

    if (uploadsCache[attachment.name]) {
        return uploadsCache[attachment.name];
    }

    const uploadLink = await getUploadAttachmentLink({
        attachment,
        token,
        permissions,
    });

    const base64 = await readFileToBuffer(attachment);
    const buffer = Buffer.from(base64, "base64");

    await makeRequest({
        url: uploadLink.link,
        method: "PUT",
        body: buffer,
        includeAuthHeader: false,
        contentType: uploadLink.contentType,
    });

    const uploadedAttachment = formatUploadedAttachment(uploadLink);

    // Don't cache attachments without names.
    if (attachment.name) {
        uploadsCache[attachment.name] = uploadedAttachment;
    }

    return uploadedAttachment;
}

async function getUploadAttachmentLink({
    attachment,
    token,
    permissions = [],
}) {
    const url = `${process.env.CDN_LINKS_URL}/uploads`;
    const body = {
        content_type: attachment.type,
        permissions,
    };

    const uploadLink = await makeRequest({
        url,
        token,
        method: "POST",
        body: JSON.stringify(body),
    });

    return uploadLink;
}

export function readFileToBuffer(attachment) {
    const fileReader = new FileReader();

    // eslint-disable-next-line promise/avoid-new, promise/no-native
    return new Promise((resolve, reject) => {
        fileReader.onerror = () => {
            fileReader.abort();
            reject(new Error("Error reading file from input"));
        };

        fileReader.onload = () => {
            const buffer = Buffer.from(fileReader.result);
            resolve(buffer);
        };

        fileReader.readAsArrayBuffer(attachment);
    });
}

export function getPermissionsForAnyCompanyAsset() {
    return [
        {
            access_type: CDN_ACCESS_TYPES.READ,
            granted_entity_type: CDN_GRANTED_ENTITY_TYPES.ANY_COMPANY,
        },
    ];
}

function formatUploadedAttachment(uploadLink) {
    return {
        id: uploadLink?.id,
        type: uploadLink?.contentType,
        url: uploadLink?.link,
    };
}

export async function uploadAttachmentSafely({
    attachment,
    token,
    permissions,
    showError,
}) {
    try {
        const uploadedAttachment = await uploadAttachment({
            attachment,
            token,
            permissions,
        });

        return uploadedAttachment;
    } catch (error) {
        showError({ error });

        return null;
    }
}
