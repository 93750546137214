import { logError } from "~services/sentry";

let lastTokenUsed;

export function initConversationsSetting() {
    window.hsConversationsSettings = {
        loadImmediately: false,
    };
}

export function openChat({ token, email }) {
    // If we already set the token to the value being passed, do nothing
    if (token && token === lastTokenUsed) return;
    lastTokenUsed = token;

    window.hsConversationsSettings = {
        identificationEmail: email,
        identificationToken: token,
    };

    try {
        window.HubSpotConversations.widget.load();
    } catch (error) {
        logError({ error });
    }
}

export function removeChat() {
    try {
        window.HubSpotConversations.widget.remove();
    } catch (error) {
        logError({ error });
    }
}
