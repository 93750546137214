/* eslint-disable prettier/prettier */
import { COPY } from "~app/copyConstants";

import { FORM_ERROR, LANGUAGES } from "~constants";

const resourceTranslations = createResourceTranslations();

export const resources = {
    [LANGUAGES.EN]: {
        translation: {
            [FORM_ERROR.FULL_ADDRESS]: FORM_ERROR.FULL_ADDRESS,
            [FORM_ERROR.GOOGLE_PLACES]: FORM_ERROR.GOOGLE_PLACES,
            [FORM_ERROR.PHONE]: FORM_ERROR.PHONE,
            [FORM_ERROR.URL]: FORM_ERROR.URL,
            [FORM_ERROR.REQUIRED]: FORM_ERROR.REQUIRED,
            ...resourceTranslations.en,
        },
    },
    [LANGUAGES.ES]: {
        translation: {
            [FORM_ERROR.FULL_ADDRESS]: "Por favor pon una direccion completa",
            [FORM_ERROR.GOOGLE_PLACES]:
                "Un error ha ocurrido cargando la dirección",
            [FORM_ERROR.PHONE]: "Por favor pon un telefono",
            [FORM_ERROR.URL]: "Por favor pon una pagina web",
            [FORM_ERROR.REQUIRED]: "Requerido",
            ...resourceTranslations.es,
        },
    },
};

function createResourceTranslations() {
    const copyValues = Object.values(COPY);

    const res = { es: {}, en: {} };

    copyValues.forEach((copyVal) => {
        res.en[copyVal.EN] = copyVal.EN;

        res.es[copyVal.EN] = copyVal.ES;
    });

    return res;
}
