import { formatTsDistance } from "~services/dateHelpers";

export function getCandidateExperience(candidate) {
    return formatTradeNameAndExperience(candidate.primary_trade);
}

export function formatTradeNameAndExperience(trade) {
    // Looks in both places to support candidate primary trade and user trade.
    const tradeName = trade?.trade?.name || trade?.name;
    const sOrEmpty = trade?.years_of_experience === 1 ? "" : "s";

    if (!tradeName) return null;

    if (!trade?.years_of_experience) return tradeName;

    return `${tradeName} · ${trade.years_of_experience} year${sOrEmpty}`;
}

export function formatPhoneFromApi(phone) {
    if (!phone) return null;

    const phoneWithoutPrefix = phone.slice(-10);
    const formattedPhone = `(${phoneWithoutPrefix.substr(
        0,
        3
    )}) ${phoneWithoutPrefix.substr(3, 3)}-${phoneWithoutPrefix.substr(6)}`;

    return formattedPhone;
}

export function formatCurrencyFromCents(cents) {
    const dollars = cents / 100;

    return `$${dollars}`;
}

export function getJobDuration(job) {
    const duration = formatTsDistance(job?.start_at, job?.end_at);

    return duration || "Ongoing";
}

export function getFullName(user) {
    if (!user) return "";
    if (user.name) return user.name;

    return `${user.first_name} ${user.last_name}`;
}

export function getEntityIdFromHubspotUrl(url) {
    if (!url) return null;

    const idStartIdx = url.lastIndexOf("/") + 1;
    const id = idStartIdx > 0 ? url.slice(idStartIdx) : "";
    const isValidId = /^[0-9]+$/.test(id);

    return isValidId ? id : null;
}

export function formatCityAndState(address) {
    if (!address?.city || !address?.state) return null;

    return `${address.city}, ${address.state}`;
}

export function getPluralSuffix(array) {
    return array?.length > 1 ? "s" : "";
}

export function formatNameSnakeCase({ name }) {
    return name?.replace(/\s/g, "_").toLowerCase();
}

export function convertToUpperCaseSnakeCase({ name }) {
    return name.replace(/\s+/g, "_").toUpperCase();
}

export function formatLocationCityAndState(location) {
    if (!location?.city || !location?.state) return "";

    return `${location.city}, ${location.state}`;
}
