import { getUserLocale } from "get-user-locale";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { resources } from "~services/translations";

const lng = getUserLocale().slice(0, 2);

i18n.use(initReactI18next).init({
    resources,
    lng,
    fallbackLng: "en",
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;
