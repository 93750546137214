import createPersistedState from "use-persisted-state";

export const LOCAL_STORAGE = {
    COMPANY: "company",
};

export function usePersistedState(key) {
    const usePersisted = createPersistedState(key);
    const [persistedState, setPersistedState] = usePersisted();

    return [persistedState, setPersistedState];
}

export function usePersistedCompany() {
    return usePersistedState(LOCAL_STORAGE.COMPANY);
}
