/* eslint max-lines: ["error", {"max": 400}] */
import { AnalyticsBrowser } from "@segment/analytics-next";
import getUnixTime from "date-fns/getUnixTime";

import { ANALYTICS_EVENTS } from "~app/constants";
import googleAnalytics from "~services/googleAnalytics";
import { logError } from "~services/sentry";

const analytics = AnalyticsBrowser.load({
    writeKey: process.env.SEGMENT_WRITE_KEY,
});

async function sendTrackEvent({ eventName, entity, mapperFunc }) {
    await runCoreFuncWithErrorHandling({
        coreFunc: sendTrackEventCore,
        coreFuncArgs: { eventName, entity, mapperFunc },
    });
}

async function sendIdentifyEvent(user) {
    await runCoreFuncWithErrorHandling({
        coreFunc: sendIdentifyEventCore,
        coreFuncArgs: { user },
    });
}

async function sendPageEvent({ url, path }) {
    await runCoreFuncWithErrorHandling({
        coreFunc: sendPageEventCore,
        coreFuncArgs: { url, path },
    });
}

async function sendReferralRequestsSentEvent({
    entityId,
    entityType,
    companyId,
}) {
    await sendTrackEvent({
        eventName: ANALYTICS_EVENTS.REQUEST_REFERRALS,
        entity: { entityId, entityType, companyId },
        mapperFunc: mapRequestReferralsForAnalytics,
    });
}

async function sendReferralRemovedEvent(referral) {
    await sendTrackEvent({
        eventName: ANALYTICS_EVENTS.REFERRAL_REMOVED,
        entity: referral,
        mapperFunc: mapReferralForAnalytics,
    });
}

async function sendReferralHiredEvent(referral) {
    await sendTrackEvent({
        eventName: ANALYTICS_EVENTS.REFERRAL_HIRED,
        entity: referral,
        mapperFunc: mapReferralForAnalytics,
    });
}

async function sendCandidateStatusChangedEvent(candidate) {
    await sendTrackEvent({
        eventName: ANALYTICS_EVENTS.CANDIDATE_STATUS_CHANGED,
        entity: candidate,
        mapperFunc: mapCandidateAttributesForAnalytics,
    });
}

async function sendSuggestedCandidatesEvent({ users, jobId, companyId }) {
    await sendTrackEvent({
        eventName: ANALYTICS_EVENTS.SUGGESTED_CANDIDATES,
        entity: { users, jobId, companyId },
        mapperFunc: mapSuggestedCandidatesForAnalytics,
    });
}

async function sendInviteToJobEvent({ userId, jobId, companyId }) {
    await sendTrackEvent({
        eventName: ANALYTICS_EVENTS.INVITE_TO_JOB,
        entity: { userId, jobId, companyId },
        mapperFunc: mapJobInviteForAnalytics,
    });
}

async function sendCompanyUpdatedEvent(company) {
    await sendTrackEvent({
        eventName: ANALYTICS_EVENTS.COMPANY_UPDATED,
        entity: company,
        mapperFunc: mapCompanyForAnalytics,
    });
}

async function sendProjectCreatedEvent(project) {
    await sendTrackEvent({
        eventName: ANALYTICS_EVENTS.PROJECT_CREATED,
        entity: project,
        mapperFunc: mapProjectForAnalytics,
    });
}

async function sendProjectDuplicatedEvent(project) {
    await sendTrackEvent({
        eventName: ANALYTICS_EVENTS.PROJECT_DUPLICATED,
        entity: project,
        mapperFunc: mapProjectForAnalytics,
    });
}

async function sendProjectUpdatedEvent(project) {
    await sendTrackEvent({
        eventName: ANALYTICS_EVENTS.PROJECT_UPDATED,
        entity: project,
        mapperFunc: mapProjectForAnalytics,
    });
}

async function sendProjectDeletedEvent(project) {
    await sendTrackEvent({
        eventName: ANALYTICS_EVENTS.PROJECT_DELETED,
        entity: project,
        mapperFunc: mapProjectForAnalytics,
    });
}

async function sendOpenShareJobModalEvent(job) {
    await sendTrackEvent({
        eventName: ANALYTICS_EVENTS.OPENED_SHARE_JOB_MODAL,
        entity: job,
        mapperFunc: mapJobForAnalytics,
    });
}

async function sendInvitedUsersToCompanyEvent({
    numberInvited,
    companyId,
    entityId,
    entityIdList,
    entityType,
    fromCsv,
    isResend = false,
}) {
    await sendTrackEvent({
        eventName: ANALYTICS_EVENTS.INVITED_USERS_TO_COMPANY,
        entity: {
            numberInvited,
            companyId,
            entityId,
            entityIdList,
            entityType,
            fromCsv,
            isResend,
        },
        mapperFunc: mapInvitationInfoForAnalytics,
    });
}

async function sendAcceptedCompanyInvitationEvent(userCompany) {
    await sendTrackEvent({
        eventName: ANALYTICS_EVENTS.ACCEPTED_COMPANY_INVITATION,
        entity: userCompany,
        mapperFunc: mapUserCompanyForAnalytics,
    });
}

async function sendDownloadedUsersCsvEvent({ companyId, entityId }) {
    await sendTrackEvent({
        eventName: ANALYTICS_EVENTS.DOWNLOADED_USERS_CSV,
        entity: { company_id: companyId, entity_id: entityId },
    });
}

async function sendGetProjectQrCodeEvent(project) {
    await sendTrackEvent({
        eventName: ANALYTICS_EVENTS.GET_PROJECT_QR_CODE,
        entity: project,
        mapperFunc: mapProjectForAnalytics,
    });
}

async function sendGetProjectUrlEvent(project) {
    await sendTrackEvent({
        eventName: ANALYTICS_EVENTS.GET_PROJECT_URL,
        entity: project,
        mapperFunc: mapProjectForAnalytics,
    });
}

async function trackShareLink({
    anchorElement,
    eventName = ANALYTICS_EVENTS.SHARE_CLICKED,
    entityType,
    entityId,
    sharedOn,
}) {
    await analytics.trackLink(anchorElement, eventName, {
        entity_type: entityType,
        entity_id: entityId,
        shared_on: sharedOn,
    });
}

// CORE FUNCS

async function sendIdentifyEventCore({ user }) {
    const traits = mapUserAttributesForAnalytics(user);

    await analytics.identify(user.id, traits);
}

async function sendTrackEventCore({ eventName, entity, mapperFunc }) {
    const attributes = mapperFunc ? mapperFunc(entity) : entity;
    const args = attributes ? [eventName, attributes] : [eventName];

    await analytics.track(...args);
}

async function sendPageEventCore({ url, path }) {
    await analytics.page({ url, path });
    googleAnalytics.trackPageView({ url, path });
}

async function runCoreFuncWithErrorHandling({ coreFunc, coreFuncArgs }) {
    try {
        await coreFunc(coreFuncArgs);
    } catch (error) {
        logError({ error });
    }
}

// MAPPERS

function mapUserAttributesForAnalytics(user) {
    return {
        firstName: user.first_name,
        lastName: user.last_name,
        phone: user.phone_number,
        email: user.email,
    };
}

function mapCandidateAttributesForAnalytics(candidate) {
    return {
        target_user_id: candidate.user_id,
        job_id: candidate.job_id,
        status: candidate.status,
        reason: candidate.reason,
        transitioned_at: candidate.transitioned_at,
    };
}

function mapSuggestedCandidatesForAnalytics({ users, jobId, companyId }) {
    return {
        job_id: jobId,
        company_id: companyId,
        user_ids: users.map(({ id }) => id),
    };
}

function mapJobInviteForAnalytics({ userId, jobId, companyId }) {
    return {
        target_user_id: userId,
        job_id: jobId,
        company_id: companyId,
        invited_at: getUnixTime(new Date()),
    };
}

function mapCompanyForAnalytics(company) {
    return {
        company_id: company.id,
        company_name: company.name,
    };
}

function mapProjectForAnalytics(project) {
    return {
        project_id: project.id,
        project_name: project.name,
        company_id: project.company_id,
    };
}

function mapJobForAnalytics(job) {
    return {
        job_id: job.id,
        job_name: job.name,
    };
}

function mapUserCompanyForAnalytics(userCompany) {
    return {
        company_id: userCompany.id,
        company_name: userCompany.name,
        role: userCompany.role,
    };
}

function mapRequestReferralsForAnalytics({ entityId, entityType, companyId }) {
    return {
        referral_company_id: companyId,
        referral_entity_id: entityId,
        referral_entity_type: entityType,
    };
}

function mapReferralForAnalytics(referral) {
    return {
        referral_id: referral.id,
        referral_referrer_id: referral.referrer_id,
        referral_company_id: referral.company_id,
        referral_entity_id: referral.entity_id,
        referral_entity_type: referral.entity_type,
    };
}

function mapInvitationInfoForAnalytics({
    numberInvited,
    companyId,
    entityId,
    entityIdList,
    entityType,
    fromCsv,
    isResend,
}) {
    const entityIdListString = entityIdList?.join?.(",");

    return {
        number_invited: numberInvited,
        company_id: companyId,
        entity_id: entityId,
        entity_id_list: entityIdListString,
        entity_type: entityType,
        from_csv: fromCsv,
        is_resend: isResend,
    };
}

export default {
    sendIdentifyEvent,
    sendPageEvent,
    sendCandidateStatusChangedEvent,
    sendSuggestedCandidatesEvent,
    sendInviteToJobEvent,
    sendCompanyUpdatedEvent,
    sendProjectCreatedEvent,
    sendProjectDuplicatedEvent,
    sendProjectUpdatedEvent,
    sendProjectDeletedEvent,
    sendOpenShareJobModalEvent,
    sendInvitedUsersToCompanyEvent,
    sendAcceptedCompanyInvitationEvent,
    sendDownloadedUsersCsvEvent,
    trackShareLink,
    sendGetProjectQrCodeEvent,
    sendGetProjectUrlEvent,
    sendReferralRequestsSentEvent,
    sendReferralRemovedEvent,
    sendReferralHiredEvent,
    mapReferralForAnalytics,
};
