import i18n from "i18next";
import { StreamChat } from "stream-chat";

import { setConnectedStreamUserVar } from "~app/reactiveVars";

const MESSAGING_CHANNEL_TYPE = "messaging";

export const DEFAULT_SORT = { last_message_at: -1 };

let client;

export function getClient() {
    if (client) return client;
    client = StreamChat.getInstance(process.env.STREAM_IO_KEY);

    return client;
}

export async function connectStreamUser({ companyId, companyName, chatToken }) {
    const streamCompanyId = getStreamCompanyId(companyId);
    const streamClient = getClient();

    // If we have a user id, we already connected, so do nothing
    if (streamClient?.userID) return;

    const streamUser = await streamClient.connectUser(
        {
            id: convertToStreamUserId(streamCompanyId),
            name: companyName,
            language: i18n.language || "en",
        },
        chatToken
    );

    setConnectedStreamUserVar(streamUser);
}

export async function disconnectStreamUser() {
    const streamClient = getClient();
    if (!streamClient.userID) return;

    await streamClient.disconnectUser();
}

// "|" are not accepted in stream ids so we must replace
// https://gitlab.com/boomnation/api-boomnation/-/blob/develop/lib/streamChat.js#L155
export function convertToStreamUserId(userId) {
    return (userId || "").replace("|", "-");
}

export function convertFromStreamUserId(userId) {
    return (userId || "").replace("-", "|");
}

export function getFiltersForUserMessages(userId) {
    return {
        type: MESSAGING_CHANNEL_TYPE,
        members: { $in: [userId] },
        // The following ensures a message has been sent,
        // we don't want to grab a channel where messages don't exist.
        // We're passing the current date for lte to grab all recent messages
        // despite how old they are.
        last_message_at: { $lte: new Date().toISOString() },
    };
}

export function getChannelInfo(channel) {
    // eslint-disable-next-line no-underscore-dangle
    const currentUserId = channel._client.user.id;
    const { members } = channel.state;
    const { name } = channel.data;
    const isGroupChat = Object.keys(members).length > 2;

    // Currently only handles 1:1 conversations
    const messagingUserId = Object.keys(members).find(
        (userId) => userId !== currentUserId
    );
    const mappedMessagingUserId = convertFromStreamUserId(messagingUserId);
    const messagingUser = members[messagingUserId];

    const channelTitle = isGroupChat ? name : messagingUser?.user?.name;

    return {
        isGroupChat,
        channelTitle,
        messagingUser,
        messagingUserId,
        mappedMessagingUserId,
    };
}

export async function createChat({ companyId, messagingUserId }) {
    const streamClient = getClient();

    const streamCompanyId = getStreamCompanyId(companyId);
    const channel = streamClient.channel(MESSAGING_CHANNEL_TYPE, {
        members: [
            convertToStreamUserId(streamCompanyId),
            convertToStreamUserId(messagingUserId),
        ],
    });

    await channel.watch();

    return channel;
}

export async function getDashboardChannelsAndRecentMessage({
    companyId,
    limit,
    offset,
}) {
    if (!companyId) return null;

    const streamClient = getClient();
    const streamCompanyId = getStreamCompanyId(companyId);
    const filter = getFiltersForUserMessages(streamCompanyId);
    const queryOptions = { message_limit: 1, offset, limit };

    const channels = await streamClient.queryChannels(
        filter,
        DEFAULT_SORT,
        queryOptions
    );

    return channels;
}

export function getTotalUnreadMessages({ channels }) {
    if (!channels.length) return "";

    return channels.reduce(
        (total, channel) => total + channel.state.unreadCount,
        0
    );
}

export function getStreamCompanyId(companyId) {
    return `company-${companyId}`;
}
