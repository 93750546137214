import {
    formatTradeNameAndExperience,
    formatCityAndState,
    getFullName,
} from "~services/formattingHelpers";

export function getUserExperience(user) {
    const trade = findPrimaryOrFirstTrade(user?.user_trades);

    return formatTradeNameAndExperience(trade);
}

export function getUserCityAndStateString(user) {
    return formatCityAndState(user?.location);
}

export function findPrimaryTrade(trades) {
    return trades?.find((trade) => trade.is_primary);
}

export function findPrimaryOrFirstTrade(trades) {
    return findPrimaryTrade(trades) || trades?.[0];
}

export function formatAndTruncateNamesList({ users, limit = 2 }) {
    const userNamesList = users.slice(0, limit).map(getFullName);
    const userNamesListString = userNamesList.join(", ");
    const remainingNamesCount = users.length - userNamesList.length;
    const messageSuffixOrEmpty = remainingNamesCount
        ? `, and ${remainingNamesCount} others`
        : "";

    return `${userNamesListString}${messageSuffixOrEmpty}`;
}
