export function downloadCsvFile({ csvString, fileName }) {
    const blob = new Blob([csvString], { type: "text/csv" });

    downloadFile({ blob, fileName });
}

export function downloadFile({ blob, fileName }) {
    const href = window.URL.createObjectURL(blob);

    return downloadUrl({ href, fileName });
}

export function downloadUrl({ href, fileName }) {
    const anchorEl = document.createElement("a");

    anchorEl.setAttribute("href", href);
    anchorEl.setAttribute("download", fileName);
    anchorEl.click();

    // returned for tests
    return anchorEl;
}
