import {
    fromUnixTime,
    format,
    formatDistanceToNowStrict,
    formatDistanceStrict,
    formatDuration,
    intervalToDuration,
    isPast,
} from "date-fns";

export function formatTimeStamp(ts, formatString) {
    if (!ts) return "";

    return format(fromUnixTime(ts), formatString);
}

export const formatTs = (ts) => {
    if (!ts) return "";

    const date = fromUnixTime(ts);

    return format(date, "P");
};

export function formatTsDistanceFromNow(ts) {
    if (!ts) return null;
    const date = fromUnixTime(ts);

    return formatDistanceToNowStrict(date);
}

export const formatTsDistance = (startTs, endTs) => {
    if (!startTs || !endTs) return "";

    const startDate = fromUnixTime(startTs);
    const endDate = fromUnixTime(endTs);

    return formatDistanceStrict(startDate, endDate);
};

export const isInPast = (ts) => {
    const date = fromUnixTime(ts);

    return isPast(date);
};

export function getFormattedDateRange({ startAt, endAt, includeDayInDates }) {
    const formattedStartDate = formatTimestampLongDate({
        ts: startAt,
        includeDayInDates,
    });
    const formattedEndDate = formatTimestampLongDate({
        ts: endAt,
        includeDayInDates,
    });

    return formattedEndDate
        ? `${formattedStartDate} - ${formattedEndDate}`
        : formattedStartDate;
}

export function formatTimestampLongDate({ ts, includeDayInDates }) {
    const formatString = includeDayInDates ? "MMMM d, yyyy" : "MMMM yyyy";

    return formatTimeStamp(ts, formatString);
}

export function formatTimeStampToDateAndTime(ts) {
    return formatTimeStamp(ts, "PP · p");
}

export function formatTimestampShortDate(ts) {
    return formatTimeStamp(ts, "LLL d, yyyy");
}

export function formatExpiresIn(expiresAtTs) {
    if (!expiresAtTs) return "";

    const now = new Date();
    const expirationDate = fromUnixTime(expiresAtTs);
    const duration = intervalToDuration({ start: now, end: expirationDate });
    const formattedDuration = formatDuration(duration, {
        format: ["years", "months", "days"],
    });
    const agoOrEmpty = isPast(expirationDate) ? " ago" : "";

    return `${formattedDuration}${agoOrEmpty}`;
}
